.client-readonly-portal-tab-wrapper {
  .client-portal-header {
    text-align: center;
    padding: 45px 0;
    .description-input {
      input {
        width: 400px;
        display: inline-block;
        border: none;
        text-align: center;
        background-color: transparent;
        color: white;
      }
      input::placeholder {
        font-style: italic;
        text-align: center;
        color: white;
      }
      input:focus {
        box-shadow: none;
      }
    }
  }
  .client-portal-body-wrapper {
    .client-portal-body-icons-container {
      text-align: center;
      .icon {
        background-color: white !important;
        box-shadow: 0 1px 3px hsla(0, 0, 0, 0.1);
        color: #1890ff;
        font-size: 24px;
        cursor: pointer;
        background: #ffffff33;
        border-radius: 10px;
        padding: 16px;
        margin-bottom: 8px;
        &:hover {
          color: #1890ff;
        }
        &.active {
          border: 1px solid #1890ff;
        }
      }
    }
    .column-card {
      box-shadow: 0 1px 3px hsla(0, 0, 0, 0.1);
      border-radius: 10px;
    }
  }
  .icons-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;

    .icon {
      margin: 10px;
      font-size: 24px;
      cursor: pointer;
      background: #ffffff33;
      border-radius: 5px;
      padding: 6px;
      &:hover {
        color: #1890ff;
      }
    }
  }

  .title {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }
  .container {
    padding: 100px 0;
  }

  .absolute-icons {
    flex-direction: column;
    position: absolute;
    text-align: center;
    top: 0;
    left: 20px;
    z-index: 8;
    .client-portal-body-icons-container {
      margin-bottom: 20px;
      font-size: 12px !important;
    }
  }
}
