@import 'src/@aduvi/styles/global.scss';

.chat-popover {
  .ant-popover-inner {
    border-radius: 10px;
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    min-height: 500px;
    max-height: 533px;
    overflow-y: hidden;

    .header-container {
      padding: 10px;
      margin: 1px;
      background-color: $white;
      align-items: center;
    }

    .message-container {
      max-height: 250px !important;
      min-height: 250px !important;
      height: 100%;
      overflow-y: auto;
      justify-content: center;

      .message-content {
        height: fit-content;
        background: #ececec;
        padding: 10px;
        border-radius: 10px;
        margin: 4px;
        p {
          margin: 0;
        }
      }

      .new-message-indicator {
        position: absolute;
      }

      .custom-question-content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 350px !important;
        padding: 5px !important;
      }

      .custom-question {
        text-align: center;
        border-radius: 5px;
        border: 1px solid;
        border-style: groove;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 5px;
        border-color: #1890ff;
        height: 35px;
      }
      .custom-question:hover {
        background-color: #f7f7f7;
      }

      .typing {
        margin: 10px;
        font-size: 10x;
        font-style: italic;
      }

      .user-response {
        margin-left: 80px;
        background-color: #b7eb8f;
        height: auto;
      }

      .system-response {
        margin-right: 80px;
      }
    }

    .bottom-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 20px;

      .message-form-item {
        width: 90%;
        margin-bottom: 0px;
      }

      .message-input {
        border-radius: 15px;
        width: 95%;
        gap: 5px;
        margin: 3px;
      }

      .send-btn {
        border-radius: 20px;
        position: absolute;
        bottom: 70px;
        right: 30px;
      }
    }
  }
}
