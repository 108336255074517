.events-table {
  width: 100%;

  .ant-table {
    background: transparent;
  }

  .ant-table-placeholder {
    background: transparent !important;
  }

  .ant-table-row-expand-icon-cell {
    display: none !important;
  }

  tr .ant-table-cell[colspan='5'] {
    padding: 0px 0px 16px 16px;
  }
  .events-table-row {
    box-shadow: 0px 0px 8px 0px #5194d826;
    cursor: pointer;
  }
  .tickets-table-row {
    background-color: #ffffff;
    cursor: pointer;
  }
  .old-price {
    font-size: 12px;
    text-decoration: line-through;
    text-decoration-color: red;
  }
  .ticket-image {
    display: inline-block;
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
  }
  .sale-tag {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background-color: red;
    padding: 1px 5px;
    border-radius: 3px;
  }
}
