.products-table-wrapper {
  .ant-table {
    background: transparent;
  }

  .ant-table-placeholder {
    background: transparent !important;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  th {
    background: transparent;
  }
  .ant-table-thead {
    tr {
      // DARK MODE BLOCKER
      //background: rgb(249, 252, 255);
    }
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: transparent;
  }
}
