.custom-edge {
  .card {
    background: white;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  .example-5 {
    position: relative;
  }

  .example-5 svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .example-5 .line {
    stroke-dasharray: 5, 5;
    stroke-width: 3px;
    fill: transparent;
    stroke: rgba(19, 19, 19, 0.5);
    animation: svgAnimation 2s linear infinite;
  }

  @keyframes svgAnimation {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: -20;
    }
  }
}
