.dashboard-wrapper {
  background: linear-gradient(to bottom, #5fc0f4 10%, #f9fcff 30%);
  padding: 60px 60px 20px 60px;
  @media (max-width: 600px) {
    padding: 10px;
  }
  min-height: 100vh;
  position: relative;
  z-index: 0;
  .ant-col {
    .widget {
      height: 200px;

      position: relative;
      z-index: 4;
      overflow: auto;
      scrollbar-width: none;
      max-width: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
      &.weather-widget {
        border-radius: 15px;
      }
    }
  }
  .add-widget-button {
    z-index: 200;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: all 0.3s ease;
    &.icon {
      opacity: 0.5;
      color: white;
      &:hover {
        opacity: 1;
      }
    }
  }
  .delete-widget-button {
    z-index: 200;
    position: absolute;
    top: 0;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .empty {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.dashboard-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .dashboard-header {
    position: absolute;
    top: 0;

    left: 0;
    right: 0;
    height: 100vh;
    background: red;
    .add-widget-button {
      opacity: 0.3;
      position: fixed;
      bottom: 2%;
      right: 50%;
      transform: translateX(50%);
      z-index: 10000;
      transition: all 0.3s ease;
      &:hover {
        opacity: 1;
      }
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  .save-button {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 330000;
    @media (max-width: 991px) {
      display: none;
    }
  }
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
