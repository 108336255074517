.add-form-wrapper {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    padding: 20px 24px;
    margin-bottom: 0;
  }
  .ant-modal-footer {
    padding-right: 24px;
    padding-bottom: 12px;
  }
}
