.content-box-editor-wrapper {
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    display: none;
  }
}

#_cbhtml .is-sidebar {
  z-index: 100 !important;
}

.builder-ui ul,
.builder-ui ol {
  margin: 0 !important;
  padding-inline-start: 0 !important;
}

.custom-topbar button {
  border-radius: 4px;
}

.builder-ui button {
  margin: 0px;
}

.quote-block {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 20px;
  margin: 10px 0;

  .quote-content {
    pointer-events: none; // Prevent editing
    user-select: none;

    h3 {
      color: #2c3e50;
      margin-bottom: 15px;
    }

    .quote-details {
      p {
        margin: 5px 0;
        color: #495057;
      }
    }
  }
}

// Style for disabled editing
[contenteditable='false'] {
  cursor: not-allowed;
}
