@import 'src/@aduvi/styles/base/colors.scss';

.search-popover {
  .ant-popover-content {
    margin-bottom: 10px !important;
    margin-left: 5px !important;
    border-radius: 10px;
  }

  .ant-popover-inner {
    border-radius: 10px;
  }

  .search-result-content {
    width: 370px;
    min-height: 500px;
    max-height: 500px;
    overflow: auto;
  }

  .search-module {
    color: $gray-7;
  }

  .search-input-icon .ant-input-group-addon .ant-btn {
    border: none !important;
    box-shadow: none !important;
    rotate: 90deg;
    color: black !important;
  }
}
