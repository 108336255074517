.edit-job-file-card {
  margin: 5px;
  .title {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .description {
    font-size: 18px;
    color: #00000073;
    padding-left: 8px;
  }
}
