.file-menu-wrapper {
  padding: 24px 24px 12px 24px;
  height: 100%;
  min-height: calc(100vh - 125px);
  background-color: transparent;
  position: static;

  .file-search {
    margin-top: 12px;
    .ant-input {
      height: 35px;
    }
    .ant-input-group-addon {
      .ant-btn {
        height: 35px;
      }
    }
  }
  .files-start {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    .channel-edit-button {
      display: none;
    }
    &:hover .channel-edit-button {
      display: block;
    }
    &.active {
      background: rgba(0, 0, 0, 0.06) !important;
    }
  }

  .menu-collapse-wrapper {
    flex-grow: 1;
    .ant-collapse-item > .ant-collapse-header {
      padding-bottom: 0px;
    }
  }

  .row-label {
    flex-grow: 1;
    margin-right: auto;
  }

  .ant-tree .ant-tree-switcher {
    line-height: 31px;
  }
}
