.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex !important;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
