@import 'src/@aduvi/styles/base/colors.scss';

.exhibitor-form-wrapper {
  .list-letter {
    color: $blue-6;
  }
  .agenda-table {
    background: transparent;

    .ant-table-tbody {
      background: $white;
      box-shadow: 0 2px 8px 0 rgba(81, 148, 216, 0.26);
    }

    .ant-table-thead > tr > th {
      display: none;
    }
    .ant-table-thead > tr > th {
      background: transparent;
      color: gray;
      font-size: 11px;
      font-weight: 400;
    }
  }
}

.event-exhibitor-modal {
  overflow: auto;
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}
