.app-loading-wrapper {
  height: 100vh;
  width: 100%;

  .skeleton-wrapper {
    padding: 40px;
    width: 100%;
    padding-bottom: 10px;

    .main-skeleton {
      height: 100%;
      padding-left: 100px;

      .layout-skeleton {
        width: 350px;
      }
    }
  }

  .sidebar {
    width: 65px;
    height: 100%;
    background-color: #f0f0f0;
    position: fixed;
    top: 0;
    left: 0;

    .sider-items {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    .top-sider-items {
      gap: 15px;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
    }
    .bottom-sider-items {
      gap: 15px;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}
