@import 'src/@aduvi/styles/base/colors.scss';

.currentTime-wrapper {
  text-align: right;
  cursor: default;
  border-radius: 15px;

  .currentTime {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
