.statistics-widget {
  flex-wrap: wrap;
  border-radius: 0 !important;
  .block-container {
    overflow: auto;
  }
  .ant-col {
    flex-shrink: 0 !important;
    min-width: 180px !important;
  }

  .block-container {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .period-select {
    width: 200px;
    border-radius: 5px !important;
    overflow: hidden;
    .ant-select-selector {
      opacity: 0.7;
      color: rgba(0, 0, 0, 0.7);
    }
  }
}
.statistics-spinner {
  .ant-spin-container {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    overflow: hidden;
  }
}
