@import 'src/@aduvi/styles/base/colors.scss';

.editor-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;

  h2,
  h3,
  h4 {
    color: var(--secondary-font-color);
  }

  .preview-content a {
    color: var(--secondary-font-color);
  }

  .signature-canvas {
    width: 100%;
    height: 120px;
    border: 1px solid #dedfc3;
    background: #fcfddc;
    margin-top: 20px;
    cursor: crosshair;
  }

  .signature-canvas-preview {
    width: 100%;
    height: 120px;
    margin-top: 20px;
    cursor: crosshair;
    border: 1px solid transparent;
    background: transparent;
  }

  .signature-acknowledgement {
    display: block;
    text-align: center;
    font-size: 11px;
    color: $gray-6;
  }

  .mce-content-body {
    min-height: 100vh;
    height: 100%;
  }

  .mce-content-body.mce-edit-focus {
    outline: none;
  }
}
