.right-sidebar-wrapper {
  .popover-signature-title {
    color: gray !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .popover-font-title {
    color: gray !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .icon:first-child {
    margin-top: 30px !important;
  }
}

.form-layout-popover {
  max-width: 255px;
  .ant-radio-button-wrapper:before {
    display: none;
  }
  .layout-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .layout-button {
    border: none;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 33.333%;
  }
}

.colour-popover .ant-popover-inner {
  height: 430px;
  overflow-y: auto;
  overflow-x: hidden;
}
