.exhibitor-design-tab-wrapper {
  min-height: calc(100vh - 112px);
  display: flex;
  justify-content: center;
  align-items: center;

  .contact-icons {
    margin-top: 12px;
    font-size: 18px;

    .anticon {
      margin: 0 8px;
      cursor: pointer;
    }
  }

  .exhibitor-card {
    text-align: center;
  }

  .exhibitor-card .ant-avatar {
    margin-bottom: 16px;
  }

  .exhibitor-card .ant-typography-title {
    margin-bottom: 8px;
  }

  .icons-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;

    .icon {
      margin: 10px;
      font-size: 24px;
      cursor: pointer;
      background: #ffffff33;
      border-radius: 5px;
      padding: 6px;
      &:hover {
        color: #1890ff;
      }
    }
  }
}
