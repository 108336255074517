.services-availability-wrapper {
  .add-unit-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .input-number {
    width: 50px;
    height: 30px;
  }

  .icon-wrapper {
    color: #636363;
    font-size: 18px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .units-users-list-item {
    background: white;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
  }
}
