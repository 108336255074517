.header-container {
  background-color: #f9fcff;
  @media (max-width: 600px) {
    background-color: transparent;
  }

  .padding-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 12px;
    align-items: center;
  }

  .header-icon {
    font-size: 24px;
    color: grey;
  }
}
