@import 'src/@aduvi/styles/base/colors.scss';

.aduvi-layout-sider {
  background-color: $white;
  box-shadow: 0 2px 8px 0 rgba(81, 148, 216, 0.26);

  flex: 0 0 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
  width: 60px !important;
  z-index: 3;

  .ant-layout-sider-children {
    background-color: transparent;
    ul {
      padding: 0 4px;

      li {
        border-radius: 8px;
      }
    }
    .ant-menu-item-selected {
      color: $blue-6;
    }
  }

  .sider-logo {
    max-width: 45px;
    width: 45x;
    height: 45px;
    max-height: 45px;
    cursor: pointer;
  }

  .ant-layout-sider-children {
    background: transparent;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    height: 100vh;
  }

  .menu-flex-start {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    background-color: transparent;
  }

  .menu-flex-end {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    background-color: transparent;
  }
}

.sider-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-title {
  font-size: 12px;
  color: #9b9b9b;
}

.sider-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-list {
  margin: 0;
}

.sider-dropdown .ant-dropdown-menu {
  padding: 12px 16px;
  border-radius: 10px;
  .anticon {
    margin-right: 6px;
  }
}

.sidebar-popover-container {
  .popover-subtitle {
    margin-bottom: 0px;
    margin-left: 5px;
    margin-top: 10px;
    color: gray;
  }

  .brand-title {
    font-size: 18px;
    display: flex;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 0;
    cursor: pointer;
    width: 80%;
  }
}
