.ProseMirror {
  padding-bottom: 10px;
  li p {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
}

/* Color swatches */
.color {
  white-space: nowrap;

  &::before {
    background-color: var(--color);
    border: 1px solid rgba(128, 128, 128, 0.3);
    border-radius: 2px;
    content: ' ';
    display: inline-block;
    height: 1em;
    margin-bottom: 0.15em;
    margin-right: 0.1em;
    vertical-align: middle;
    width: 1em;
  }
}

.items {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;

  &.is-selected {
    border-color: #000;
  }
}

.mention {
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
  background: #e8f5fa;
  color: #1f6ca8;
}

.menu-bar-wrapper {
  background: #f8f8f8;
  padding: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.floating-menu,
.bubble-menu {
  display: flex;
  background-color: #0d0d0d10;
  padding: 0.2rem;
  border-radius: 5px;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.bubble-menu button {
  color: #fff;
}

@media (prefers-color-scheme: dark) {
  .color::before,
  .mention {
    border-color: rgba(255, 255, 255, 0.7);
  }
  .items {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    box-shadow:
      0 0 0 1px rgba(255, 255, 255, 0.05),
      0px 10px 20px rgba(255, 255, 255, 0.1);
  }
  .item {
    color: #fff;

    &.is-selected {
      border-color: #666;
    }
  }

  .floating-menu,
  .bubble-menu {
    background-color: #f5f5f5;
    button {
      color: #0d0d0d;
    }
  }
}

.awesome-wysiwyg-editor {
  margin: 12px;
}

.awesome-wysiwyg-editor * {
  margin: 0;
}

.ProseMirror-focused {
  outline: none;
}

.tip-tap-wrapper {
  background: #ffffff;
  border-radius: 8px;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
