.invoices-wrapper {
  height: 100vh;
  padding: 50px;

  .invoice {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 8px 0 rgba(81, 148, 216, 0.26);
  }
}
