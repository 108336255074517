@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&family=Oswald&family=Poppins&family=Roboto&family=Rubik&family=Work+Sans&display=swap');

.widgets-container {
  padding: 60px 60px 20px 60px;
  @media (max-width: 600px) {
    padding: 10px;
  }
  min-height: 100%;
  max-height: 100%;
  overflow-x: auto;
}
