@import 'src/@aduvi/styles/base/colors.scss';

.event-drawer-wrapper {
  .ant-drawer-body {
    padding: 0;
    overflow-x: hidden;
  }
  .ant-tabs {
    padding: 0 24px 24px;
  }
  .selected-event-details {
    padding: 16px 24px;
  }
  .event-title {
    color: inherit;
    margin-bottom: 5px;
  }
  .event-date,
  .event-location {
    color: inherit;
  }
  .contact-card {
    position: relative;
    top: -25px;
  }

  .event-name-input {
    width: 100%;
    font-size: 24px;
    color: $white;
    border: none;
    border-bottom: 1px solid $white;
    background-color: transparent;
    padding: 0;
    line-height: 24px;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    .ant-input {
      padding: 0;
    }
  }
  .input-column:last-child {
    flex-grow: 1 !important;
    max-width: none !important;
  }
  .note {
    font-size: 13px;
  }
}
