@import 'src/@aduvi/styles/base/colors.scss';

.activity-wrapper {
  .activity-model {
    color: $blue-6;
    font-weight: bold;
  }
  .activity-fields {
    color: $blue-6;
  }
}
