.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

.min-h-100vh {
  min-height: 100vh;
}

.h-100vh {
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.pointer-events-none {
  pointer-events: none;
}

.w-half {
  width: 50%;
}

.flex-1 {
  flex: 1;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.b-radius-10 {
  border-radius: 10px !important;
}

.aduvi-side-background-image {
  min-height: 100vh;
  max-height: 100vh;
  flex-shrink: 0;
  opacity: 0.9;
  object-fit: cover;
  object-position: center;
}
