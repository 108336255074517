.settings-assets-wrapper {
  padding: 60px 60px 0 60px;
  @media (max-width: 600px) {
    padding: 10px;
  }
  min-height: 100%;
  max-height: 100%;

  .mobile-view-title {
    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .assets-radio-group {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      justify-content: flex-start;
    }
  }

  .mobile-view-search {
    @media (max-width: 768px) {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .mobile-view-tabs {
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }
}
