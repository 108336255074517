.menu-items-wrapper {
  height: 60px;
  position: fixed;
  bottom: 0;
  .ant-menu-horizontal::before,
  .ant-menu-horizontal::after {
    content: none !important;
  }
  .ant-menu-item-icon {
    font-size: 23px !important;
  }
  .ant-menu-title-content {
    display: none !important;
  }
}
