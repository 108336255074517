.select-node-wrapper {
  .node-row {
    box-shadow: 0px 2px 8px 0px #5194d826;
    background: #ffffff;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
  }
  .node-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
}
