.client-portal-artwork-wrapper {
  border-radius: 10px;
}

.artwork-modal-wrapper {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    margin-bottom: 0;
    padding: 10px !important;
    border-bottom: 1px solid lightgray;
  }
  .ant-modal-body {
    .comments-wrapper {
      flex-direction: column;
      .comments-footer {
        position: absolute;
        bottom: 0;
      }
      .comments-container {
        height: 350px;
        overflow: auto;
      }
    }
    .comment-card {
      margin-bottom: 10px;
      border-radius: 10px;
      .comment-owner {
        font-size: 10px;
      }
      .ant-card-body {
        padding: 10px;
      }
    }
    padding: 10px !important;
    .image-wrapper {
      position: relative;
    }

    .comment-dot {
      position: absolute;
      height: 18px;
      width: 18px;
      background-color: #1890ff;
      display: block;
      border: 5px solid white;
      border-radius: 50%;
    }
    .comment-column {
      overflow: auto;
      max-height: 450px;
    }
    .comment-text {
      position: absolute;
      background-color: white;
      color: black;
      font-size: 13px;
      padding: 10px;
      &::before {
        width: 10px;
        height: 10px;
        display: block;
        content: '';
        background-color: white;
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
    .add-comment-button {
      width: 100%;
      display: block;
    }
  }
}
