.contacts-rows-wrapper {
  .single-letter {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #1890ff;
  }

  .single-contact-wrapper {
    box-shadow: 0px 2px 8px 0px #5194d826;
    background: #ffffff;
    height: 47px;

    .description {
      color: #00000073;
      font-size: 12px;
      font-weight: 500;
    }
    .single-column {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        padding: 3px 5px;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
}
