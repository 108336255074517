.login-card {
  min-width: 350px;
  max-width: 500px;
  border-radius: 10px;

  .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item-control {
    background-color: transparent !important;
  }

  .ant-form-item-row {
    display: block !important ;
  }

  .description-input {
    input {
      border: none;
      text-align: center;
    }
    input::placeholder {
      font-style: italic;
      text-align: center;
    }
  }
}
