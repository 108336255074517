.timeline-container {
  margin-top: 30px;
  border-radius: 2px;
  background-color: transparent;

  .vis-item {
    color: white;
    border-radius: 4px;
    background-color: #1890ff !important;
  }
  .vis-item.vis-selected {
    background-color: #0e76d8 !important;
  }
}
