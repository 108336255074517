@import 'src/@aduvi/styles/base/colors.scss';

.services-table {
  width: 100%;

  .ant-table {
    background: transparent;
  }

  .ant-table-placeholder {
    background: none !important;
  }

  .ant-table-row-expand-icon-cell {
    display: none !important;
  }

  tr .ant-table-cell[colspan='5'] {
    padding: 0px 0px 0px 16px;
  }

  tr.ant-table-expanded-row > td {
    background: transparent;
  }

  .service-table-row {
    box-shadow: 0px 0px 8px 0px #5194d826;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .ant-table-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    padding: 8px 12px;
    vertical-align: middle;
  }
}
