.logged-user-card {
  border-radius: 10px;
  min-width: 350px;

  position: relative;
  .card-header {
    padding-top: 80px;
    text-align: center;

    padding-bottom: 17px;
    .ant-tabs-tab {
      padding: 0 0 7px;
    }
  }
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled) {
    border: 5px solid white;
    margin: 0;
  }

  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
    padding: 0;
    margin: 0;
    border: 5px solid white;
  }
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item:hover::before {
    width: 100%;
    height: 100%;
  }

  .user-image {
    position: absolute;
    top: -50px;
    left: -50px;
    .ant-upload-list-item-container {
      margin-inline: 0 !important;
    }
    .ant-upload-select {
      padding: 0;
      .ant-upload {
        background: #d9d9d9;
        border-radius: 50%;
        margin: 0;
      }
    }
  }

  .client-booking-list-wrapper {
    .booking {
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.06);
    }
  }
}
