.box-shadow {
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.06);
}
.white-box {
  margin-bottom: 1px;
  height: 45px;
}

.title-style {
  text-transform: uppercase;
}

.custom-popover .ant-popover-arrow {
  display: none;
}
