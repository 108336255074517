.conversation-chat-wrapper {
  position: relative;
  height: 100vh;
  box-shadow: 0px 4px 9px 0px #5194d840;
  background: white;
  border-radius: 10px;

  .chat-message-container {
    overflow-y: auto;
    padding-top: 10px;

    @media (min-width: 1920px) {
      max-height: calc(80vh - 50px);
      min-height: calc(80vh - 50px);
    }

    @media (min-width: 2560px) {
      max-height: calc(85vh - 10px);
      min-height: calc(85vh - 10px);
    }

    .message-block {
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:hover .message-settings-button {
        display: inline-block;
      }
    }
    .message-settings-button {
      display: none;
    }
    .message-content {
      max-height: 80vh;
    }

    .message {
      display: flex;
      margin-top: 10px;
      font-weight: 400;
    }
    .join-to-channel {
      position: absolute;
      top: 72px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(1px);
      z-index: 9;
    }
  }

  // File list in the conversation
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    height: 70px;
    width: 80px;
  }

  .file-list-read-only {
    .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn {
      display: none;
    }
  }
}
