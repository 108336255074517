.project-wrapper {
  .banner {
    height: 200px;
    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .ant-typography {
      color: white;
      font-weight: 400;
    }
  }

  .contact-container {
    position: relative;
    top: -80px;
    padding: 0 40px;
  }
}
