// General
$white: #fff;
$black: #000;

$blue-6: #1890ff;

$green-5: #52c41a;

$gray-8: #595959;
$gray-7: #8c8c8c;
$gray-6: #8b8b8b;
$gray-5: #9b9b9b;

$red: #e10505;

// Common
.color-white {
  color: $white;
}
.bg-color-white {
  background-color: $white;
}

.color-black {
  color: $black;
}

.bg-color-black {
  background-color: $black;
}

// Blue

.color-blue-6 {
  color: $blue-6;
}
.bg-color-blue-6 {
  background-color: $blue-6;
}

// Gray

.color-gray-8 {
  color: $gray-8;
}

.color-gray-5 {
  color: $gray-5;
}

.bg-color-gray-8 {
  background-color: $gray-8;
}

// Red
.color-red {
  color: $red !important;
}

.bg-color-red {
  background-color: $red !important;
}

// Green
.color-green-5 {
  color: $green-5;
}

.color-orange-5 {
  color: #faad14;
}
