.job-management-status-wrapper {
  .paragraph {
    color: #9b9b9b;
    font-size: 12px;
    font-weight: 400;
  }
  .units-users-list-item {
    background: white;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .icon-wrapper {
    margin-top: 3px;
    color: #636363;
    font-size: 18px;
  }
  .ant-color-picker-trigger .ant-color-picker-color-block {
    width: 18px;
    height: 18px;
  }
  .ant-color-picker-trigger {
    width: 24px;
    height: 24px;
    border-radius: 2px;
  }
  .ant-collapse-header {
    padding: 0px !important;
    margin-bottom: 20px;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
}
