.edit-job-drawer-wrapper {
  .project-banner {
    height: 200px;
    position: relative;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    padding: 24px;
    color: white;
    .ant-typography {
      color: white;
      font-weight: 400;
    }
    .image-icon {
      position: absolute;
      right: 24px;
      top: 72px;
      .anticon {
        font-size: 20px;
      }
    }
    .expand-icon {
      position: absolute;
      right: 24px;
      top: 24px;

      .anticon {
        font-size: 20px;
      }
    }
  }
}
