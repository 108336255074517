@import 'src/@aduvi/styles/base/colors.scss';

.profile-wrapper {
  .user-picture-container {
    flex-direction: column;

    .user-picture-items {
      margin-top: 100px;

      .ant-upload.ant-upload-select {
        height: 140px !important;
        width: 140px !important;
      }

      .ant-upload-list-item-container {
        height: 140px !important;
        width: 140px !important;
      }
    }
  }

  .remove-image-button {
    border-radius: 50%;
    border: 1px solid $gray-8;
    color: black;
    position: absolute;
    margin-top: 140px;
    margin-left: 140px;
  }
}
