.contact-card-wrapper {
  box-shadow: 0px 2px 8px 0px #5194d826;
  border: 1px solid #f0f0f0;
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #f0f0f0, #f0f0f0);
  border-radius: 2px;
  .contact-info {
    padding: 24px !important;
  }
  .corporate {
    font-size: 12px;
    color: #00000073;
  }
  .name {
    color: #000000d9;
    font-size: 16px;
  }
  .email {
    color: #00000073;
    font-size: 14px;
    margin-top: 25px;
  }
  .phone {
    color: #00000073;
    font-size: 14px;
  }
  .contact-buttons {
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
    padding: 0;
    height: (40px);
    padding: 12px, 0px, 12px, 0px;
  }
  .copy-icon {
    color: #1890ff;
  }
}
