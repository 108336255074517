.invoice-container {
  padding: 70px 60px;
  border: 1px solid #ccc;
  border-top: 10px solid red;

  background-color: #fff;
  margin-bottom: 20px;
  .invoice-table {
    th {
      background: transparent !important;
      color: rgb(145, 145, 145) !important;
      text-align: center !important;
      &:first-child {
        text-align: left !important;
      }
      &:last-child {
        text-align: right !important;
      }
    }

    td {
      text-align: center;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }

    &.summary-table {
      max-width: 250px;
      margin-left: auto;
      th {
        display: none;
      }
      tbody {
        display: none;
      }
    }
  }
}

.invoice-header .logo {
  font-size: 24px;
  font-weight: bold;
  color: red;
}
