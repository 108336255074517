.leads-wrapper {
  padding: 60px 60px 0 60px;
  min-height: 100%;
  max-height: 100%;
  @media (max-width: 600px) {
    padding: 10px;
  }
  &.locked-scroll {
    height: 100vh;
    overflow: hidden;
  }
  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.452);
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 2;
    backdrop-filter: blur(1.4px);
    overflow: hidden;
  }

  // TODO check this
  .draggable-columns {
    position: relative;
    z-index: 2 !important;
    margin-top: 20px;
    box-shadow: 0px 2px 8px 0px #5194d826;
    background: #ffffff;
    align-items: center;
    border-radius: 2px;
    width: 100%;
    opacity: 1 !important;
    overflow-x: auto;
    overflow-y: hidden;
    height: 60px;
  }
  .editable-table {
    position: relative;
    z-index: 20;
  }
}
