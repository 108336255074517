@import 'src/@aduvi/styles/base/colors.scss';

.settings-management-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  .settings-details-container {
    padding: 60px 60px 20px 60px;
    @media (max-width: 600px) {
      padding: 10px;
    }
    min-height: 100%;
    max-height: 100%;
    overflow-x: auto;
  }

  .mobile-settings-details-container {
    padding: 20px 20px 20px 20px;
    min-height: 100%;
    max-height: 100%;
    overflow-x: auto;
  }

  .info-text {
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 0px;
    line-height: 22px;
  }
}
