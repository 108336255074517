@import 'src/@aduvi/styles/base/colors.scss';

.editable-cell-wrapper {
  .editable-cell {
    transition: all 0.3s ease;
    padding: 0 5px;

    .cell-value {
      border-radius: 8px;
      padding: 3px 5px;
      display: inline-block;
    }
    .edit-button {
      visibility: hidden;
      padding: 0 3px;
      height: auto;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    .edit-button {
      span {
        height: auto !important;
      }
      visibility: visible;
      &:hover {
        background-color: transparentize($blue-6, 0.7);
      }
    }
  }
  .ant-row {
    font-size: inherit !important;
  }
  .ant-col {
    font-size: inherit !important;
  }
}
.style-button {
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
}
.style-modal.ant-card {
  border-radius: 5px;
  box-shadow: 0 1px 3px hsla(0, 0, 0, 0.3);
}
