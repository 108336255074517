.limit-visibility-wrapper {
  .ant-card .ant-card-body {
    padding: 0;
  }

  .ant-card .ant-card-head {
    min-height: 40px;
  }

  .and-or-radio {
    border: 1px solid #b9b9b9;
    border-radius: 20px;
  }

  .delete-icon {
    color: #ff4d4f;
  }

  .condition-row {
    background: #eeeeee;
    border-bottom: 1px solid #dddddd;
  }
}
