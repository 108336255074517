@import 'src/@aduvi/styles/global.scss';

.customizable-table-wrapper {
  .bulk-actions-wrapper {
    position: fixed;
    top: 90%;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent;
    left: 0;
    right: 0;
    z-index: 550;

    .table-selected-col {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 57px;
      align-items: center;
      justify-content: center;
      background: $blue-6;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      color: $white;
    }
  }

  .table-selected-rows {
    position: fixed;
    z-index: 9999;
    margin-left: auto;
    margin-right: auto;
    top: 90%;
    height: 60px;
    max-width: 417px;
    background: white;
    box-shadow: 0px 4px 9px 0px #5194d840;
    border-radius: 10px;

    .table-selected-col {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 57px;
      align-items: center;
      justify-content: center;
      background: $blue-6;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      color: $white;
    }
  }
}

.selected-buttons {
  height: 100%;
  width: 72px;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.lead-table-wrapper {
  .ant-table-wrapper .ant-table-cell {
    padding: 5px 15px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: transparent;
  }

  .ant-table-wrapper .ant-table-thead > tr > td {
    background: transparent;
  }

  .header-cells-title {
    color: #00000073;
    font-size: 12px;
  }
  th {
    background: transparent;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-placeholder {
    background: transparent !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    .custom-table-cell {
      .custom-field-value {
        font-size: inherit !important;
        .ant-row {
          font-size: inherit !important;
        }
        .list-options-wrapper {
          .list-options-green {
            .ant-select-selector {
              background-color: #b7eb8f;
            }
          }

          .list-options-gray {
            .ant-select-selector {
              background-color: #f0f0f0;
            }
          }

          .list-options-yellow {
            .ant-select-selector {
              background-color: #ffe58f;
            }
          }

          .list-options-red {
            .ant-select-selector {
              background-color: #ff7875;
            }
          }
        }
      }
    }
  }
}

.minimal-table {
  .ant-table-wrapper .ant-table-cell {
    padding: 0px 0px;
  }
}

.regular-table {
  .ant-table-wrapper .ant-table-cell {
    padding: 5px 15px;
  }
}

.expand-table {
  .ant-table-wrapper .ant-table-cell {
    padding: 10px 25px;
  }
}
