@import 'src/@aduvi/styles/base/colors.scss';

.create-quote-wrapper {
  .ant-select-selection-item {
    color: $blue-6 !important;
  }
  .ant-table {
    background: transparent;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: transparent;
    color: #bfbfbf;
  }
}
