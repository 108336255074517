.message-wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px #5194d826;
  border-radius: 2px;
  padding: 10px 16px 10px 16px;
  margin-bottom: 24px;
  .check-icon {
    color: #52c41a;
  }
}
