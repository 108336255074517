.client-portal-make-payment-wrapper {
  .invoice-divider {
    border: 1px solid rgba(211, 211, 211, 0.651);
  }
  .payment-suggestion {
    flex-grow: 1;
  }

  .card-element {
    padding: 7px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
}
