.quote-wrapper {
  .ant-table {
    background: transparent;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: transparent;
    color: #bfbfbf;
  }

  .ant-table th:last-child,
  .ant-table td:last-child {
    text-align: right;
  }
}
