.proposal-preview-modal {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    padding: 20px 24px;
  }
  .middle-part {
    box-shadow: 0px 9px 28px 8px #0000000d;
    background: #292929;
    height: 400px;
  }
  .red-square {
    color: #cb6c6c;
    background: #efefef;
    padding: 24px;
    padding-bottom: 40px;
  }
  .info-wrapper {
    column-gap: 8px;
  }
}
