.client-portal-upsells-wrapper {
  border-radius: 10px;
  .product-card {
    position: relative;

    .sale-tag {
      padding: 4px 8px;
      color: white;
      background-color: red;
      position: absolute;
      top: 3px;
      right: -4px;
    }
  }
}
