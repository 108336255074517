.drag-drop-modal {
  padding: 60px 60px 20px 60px;
  @media (max-width: 600px) {
    padding: 10px;
  }
  background: linear-gradient(to bottom, rgba(15, 80, 139, 0.1), rgba(15, 80, 139, 0.1));
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  .widget {
    background-color: rgba(255, 255, 255, 0.325);
    height: 200px;
    border-radius: 15px;
    border: 2px dashed white;
    position: relative;
    z-index: 4;
    transition: all 0.3s ease;
  }
  .empty-widget {
    height: 200px;
  }
  .add-widget-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    z-index: 5;
    transition: all 0.3s ease;

    &:hover span {
      opacity: 1;
    }
    &:hover ~ .widget {
      background-color: rgba(129, 129, 129, 0.502);
    }
    span {
      opacity: 0;
      font-size: 50px;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;
      cursor: pointer;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.457);
    }
  }
  .add-widget-button {
    visibility: hidden;
  }
}
.widgets-modal {
  .ant-modal-body {
    overflow: auto;
  }
  .selected {
    border: 1px solid #5fc0f4;
  }

  .widget-card {
    .ant-card-body {
      padding: 10px;
    }
    .card-title {
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .widget-size {
        text-align: center;
        margin-bottom: 0;
        display: block;
        color: gray;
      }
    }
  }
}
