.entity-card {
  th {
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.06);
    opacity: 0.5;
    font-size: 10px !important;
    background: transparent !important;
    padding: 5px !important;
  }
  td * {
    font-size: 12px !important;
  }

  border-radius: 10px;
  width: 100%;
  height: 100% !important;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .entity-list-wrapper {
    height: 100% !important;
    .entity:not(:last-child) {
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.06);
    }
  }
}
