.file-wrapper {
  position: relative;
  height: 100vh;
  overflow-y: auto;

  .folder-button {
    margin: 0 8px;
    border-radius: 8px;
    min-height: 38px;
    min-width: 120px;
  }

  .file-list {
    width: 100%;
    padding: 10px;

    .file-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

      &:last-child {
        margin-bottom: 0;
      }

      .ant-typography {
        margin-right: auto;
        display: flex;
        align-items: center;

        .anticon {
          margin-right: 8px;
        }
      }

      > div {
        margin-left: 12px;
        flex-grow: 1;
      }

      > .ant-typography.type-secondary {
        flex-shrink: 0;
      }
    }
  }

  .ant-tree-treenode {
    width: 100%;
  }
}
