.client-portal-order-summary-wrapper {
  min-height: 100%;

  @media (max-width: 575px) {
    padding: 10px;
  }

  .invoice-divider {
    border: 1px solid rgba(211, 211, 211, 0.651);
  }

  .invoice-total {
    .sub-title {
      @media (max-width: 575px) {
        flex-grow: 1;
      }
    }

    .payment-title {
      flex-grow: 1 !important;
    }
  }
}
