.library-menu-wrapper {
  height: 100%;
  background-color: transparent;
  position: static;

  .library-menu-collapse-wrapper {
    flex-grow: 1;
    .ant-collapse-item > .ant-collapse-header {
      padding-bottom: 0px;
    }
  }

  .row-label {
    flex-grow: 1;
    margin-right: auto;
  }
}
