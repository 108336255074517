.automation-history-container {
  text-align: center;

  .history-list {
    border-radius: 8px;
    padding: 20px;
  }

  .history-list-item {
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 0;
  }

  .history-list-item:last-child {
    border-bottom: none;
  }

  .history-list-item .ant-list-item-meta-description {
    text-align: left;
  }

  .history-list-item .ant-list-item-meta-title {
    text-align: left;
    font-weight: 400;
  }

  .history-list-item .ant-list-item-meta-avatar {
    align-self: center;
  }
}
