.build-tab-wrapper {
  min-height: calc(100vh - 63px);
  .icons-container {
    display: flex;
    flex-direction: column;

    .icon {
      margin: 10px;
      font-size: 24px;
      cursor: pointer;
      background: #ffffff33;
      border-radius: 5px;
      padding: 6px;
      &:hover {
        color: #1890ff;
      }
    }
  }
  .enable-widget {
    box-shadow: 0px 2px 0px 0px #00000004;
    background: #ffffff33;
  }
  .booking-form {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 9px 3px #00000017;
    background: white;
    width: 45%;
    border-radius: 10px;
    margin-left: 20px;
  }
  .package-wrapper {
    box-shadow: 0px 2px 8px 0px #5194d84d;
    border-radius: 5px;
    padding: 10px;
    word-wrap: break-word;
  }
  .title {
    font-size: 30px;
    font-weight: 500;
  }

  .price-tag {
    color: #1890ff;
  }

  .ant-divider .ant-divider-inner-text {
    padding-inline: 0.2em;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    border-block-start: 0 rgba(5, 5, 5, 0.1);
  }

  .add-field-divider {
    opacity: 0;
  }

  .add-field-wrapper:hover .add-field-divider {
    opacity: 1;
  }
  .icons-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;

    .icon {
      margin: 10px;
      font-size: 24px;
      cursor: pointer;
      background: #ffffff33;
      border-radius: 5px;
      padding: 6px;
      &:hover {
        color: #1890ff;
      }
    }
  }
  .shopping-basket {
    position: absolute;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
    background-color: white;
  }

  .form-wrapper {
    position: relative;
  }
}

.booking-form-dropdown .ant-dropdown-menu {
  height: 315px;
  overflow-y: auto;
  overflow-x: hidden;
}
