@import 'src/@aduvi/styles/base/colors.scss';

.package-pricing-container {
  .delete-icon {
    font-size: 16px;
    color: $gray-8;
    cursor: pointer;
  }
  .delete-first-icon {
    padding-top: 28px;
  }
}
