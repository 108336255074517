.quotes-table {
  .ant-table {
    background: transparent;

    th {
      background: transparent !important;
      text-align: center !important;
      color: gray !important;
      font-weight: 400 !important;
      text-transform: uppercase;

      &:first-child {
        text-align: left !important;
      }
      &:last-child {
        text-align: right !important;
      }
    }
    td {
      background: transparent;
      text-align: center;

      &:first-child {
        text-align: left !important;
      }
      &:last-child {
        text-align: right !important;
      }
    }
  }

  &.summary {
    max-width: 250px;
    margin-left: auto;
    thead {
      display: none;
    }
    .ant-table-summary {
      background: transparent !important;

      th {
        display: none;
        background: transparent;
        td {
          background: transparent;
        }
      }
    }

    tbody {
      display: none;
    }
  }
}
