.reports-wrapper {
  min-height: 100%;
  max-height: 100%;
  padding: 60px 60px 0 60px;
  position: relative;

  .grid-item-wrapper {
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .edit-layout-button {
    z-index: 200;
    position: absolute;
    top: 60px;
    right: 60px;
    transition: all 0.3s ease;
    &.icon {
      opacity: 0.5;
      color: black;
      &:hover {
        opacity: 1;
      }
    }
  }

  .spin-container {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    width: 10px;
    height: 10px;
    background-image: none;
  }
  .react-grid-item > .react-resizable-handle::after {
    width: 10px;
    height: 10px;
    border-color: gray;

    border-bottom-right-radius: 50px;
  }

  .chart-container {
    position: relative;
    .type-selection {
      position: absolute;
      top: 20px;
      right: 20px;
      min-width: 80px;

      overflow: hidden;
      .ant-select-selector {
        opacity: 0.7;
        color: rgba(0, 0, 0, 0.7);
        background: transparent;
        border: none;
      }
    }
    padding: 20px;
    width: 100%;
    height: 100%;

    .chart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}
