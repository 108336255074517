.products-design-tab-wrapper {
  min-height: calc(100vh - 112px);
  display: flex;
  justify-content: center;
  align-items: center;

  .contact-icons {
    margin-top: 12px;
    font-size: 18px;

    .anticon {
      margin: 0 8px;
      cursor: pointer;
    }
  }

  .product-card-row {
    display: flex;
    align-items: center;
    padding: 20px 0;

    .product-image {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }

    .product-details {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ant-typography-title {
        margin-bottom: 10px;
      }

      .ant-typography-paragraph {
        margin-bottom: 8px;
      }
    }
  }

  .product-card {
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .product-card .ant-avatar {
    margin-bottom: 16px;
  }

  .product-card .ant-typography-title {
    margin-bottom: 8px;
  }

  .icons-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;

    .icon {
      margin: 10px;
      font-size: 24px;
      cursor: pointer;
      background: #ffffff33;
      border-radius: 5px;
      padding: 6px;
      &:hover {
        color: #1890ff;
      }
    }
  }
}
