.edit-field-wrapper {
  border: 1px solid #c4dae5;
  box-shadow: 0px 2px 8px 0px #5194d826;
  border-radius: 5px;

  .ant-divider {
    margin: 0;
  }
}

.edit-field-form-wrapper .ant-col .ant-form-item {
  margin-bottom: 4px !important;
}
