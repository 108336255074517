.authentication-wrapper {
  height: 100vh;

  .ant-tabs-nav::before {
    display: none !important;
  }

  .ant-divider {
    margin: 0 0;
  }

  .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-tabs .ant-tabs-tab-btn {
      width: 50px;
      text-align: center;
    }
  }

  .logo {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center;
  }
}

.accept-invitation-form {
  width: 100%;
  min-height: 100vh;
}
