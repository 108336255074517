@import 'src/@aduvi/styles/base/colors.scss';

.settings-menu-wrapper {
  padding: 24px 24px 12px 24px;
  height: 100%;
  min-height: calc(100vh - 125px);
  // DARK MODE BLOCKER
  // background-color: $white;
  background-color: transparent;
  position: static;

  .title {
    font-size: 24px;
    font-weight: 500;
  }

  .settings-group-title {
    color: inherit;
    opacity: 0.8;
    margin: 30px 0 0 2px;
    font-size: 12px;
  }

  .ant-input {
    height: 40px;
  }

  .ant-input-group-addon {
    height: 40px;
    .ant-btn {
      height: 40px;
    }
  }
  .settings-search {
    margin-top: 12px;
  }
  .ant-menu {
    border: 0px;
    margin-top: 0px;
    ul {
      border: transparent;
    }
    li {
      padding: 0;
      padding-inline: 10px;
      background-color: transparent;
      font-size: 16px;
    }

    .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item {
      padding-inline: 0;
    }
  }
}
