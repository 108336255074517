.edit-row-layout-drawer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 60px) !important;
  background-color: white;
  box-shadow: 0 1px 6px hsla(0, 0, 0, 0.3);
  height: 55%;
  left: 60px;
  z-index: 21;
  overflow-y: visible;

  .content {
    padding-right: 40px;
    padding-left: 40px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
    color: #000000d9;
  }
  .description {
    font-size: 16px;
    font-weight: 300;
    margin-left: 4px;
    color: #000000d9;
  }
  .header {
    margin: 24px 24px 0 24px;
    box-shadow: none;
  }
  .close-button {
    color: #00000073;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .fields_wrapper {
    position: absolute;
    top: 60px;
    bottom: 20px;
    margin-top: 2rem;
    left: 0;
    right: 0;
    overflow-y: auto;
  }
}
