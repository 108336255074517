@use 'sass:math';
@import 'src/@aduvi/styles/global.scss';

.pipeline-wrapper {
  margin-top: 60px;

  .breadcrumb {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin: auto;
    text-align: center;
    top: 50%;
    width: 100%;
    height: 44px * 1.5;
    transform: translateY(-50%);
    z-index: 1;
    background-color: $white;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 3px;
    }
  }

  .breadcrumb a {
    position: relative;
    display: flex;
    flex-grow: 1;
    text-decoration: none;
    margin: auto;
    height: 100%;
    padding-left: 14px;
    padding-right: 0;
    border: 1px solid #dfdfdf;
  }

  .breadcrumb a:first-child {
    padding-left: 44px / 2.5;
  }

  .breadcrumb a:last-child {
    padding-right: 44px / 2.5;
  }

  .breadcrumb a:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 44px * 1.5;
    height: 44px * 1.5;
    top: 0;
    right: math.div(44px, 1.35) * -1;
    background-color: $white;
    border-top-right-radius: 5px;
    transform: scale(0.707) rotate(45deg);
    box-shadow: 1px -1px #dfdfdf;
    z-index: 1;
  }

  .breadcrumb a:last-child:after {
    content: none;
  }

  .breadcrumb__inner {
    display: flex;
    flex-direction: column;
    margin: auto;
    z-index: 2;
  }

  .breadcrumb a.active {
    background: #e6f7ff;
  }

  .breadcrumb a:hover {
    background: #f1f8fc;
  }

  .breadcrumb a.active:after {
    background: #e6f7ff;
  }

  .breadcrumb a:hover:after {
    background: #f1f8fc;
  }

  @media all and (max-width: 1000px) {
    .breadcrumb {
      font-size: 12px;
    }
  }

  @media all and (max-width: 710px) {
    .breadcrumb {
      height: 60px;
    }

    .breadcrumb a {
      padding-left: 44px / 1.5;
    }

    .breadcrumb a:after {
      content: '';
      width: 44px * 1;
      height: 44px * 1;
      right: math.div(44px, 2) * -1;
      transform: scale(0.707) rotate(45deg);
    }
  }
  .leads-pipeline-table-wrapper {
    .header-cells-title {
      color: #00000073;
      font-size: 12px;
    }
    .ant-table-thead {
      tr {
        background: rgb(249, 252, 255);
      }
      th {
        background: transparent;
        .ant-table-cell {
          content: none;
        }
      }
    }
  }
}
