@import 'src/@aduvi/styles/base/colors.scss';

.availability-modal {
  max-height: 80vh;
  .availability-calendar td {
    padding: 0 !important;
  }
  .availability-calendar-cell {
    min-width: 40px;
    padding: 12px 10px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
  }
  .availability-calendar-title {
    color: $blue-6;
  }
  .ant-modal-body {
    max-height: 60vh;
  }
  .single-unit-calendar,
  .ant-modal-body {
    overflow: auto !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE/Edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome/Safari/Opera */
    }
  }
  .selected-dates {
    margin-bottom: 8px;
    max-height: 200px;
    resize: none;
    height: 100%;
  }

  .added-dates {
    background-color: #f6ffed;
    color: #52c41a;
  }
  .removed-dates {
    background-color: #fff2f0;
    color: #ff4d4f;
  }
  .ant-picker-date-panel {
    thead {
      display: none;
    }
  }
}
