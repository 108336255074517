.product-variation-wrapper {
  .grow:nth-child(2) {
    flex-grow: 1;
  }
  .grow:nth-child(3) {
    flex-grow: 1;
  }

  .form-item {
    margin-bottom: 0;
    width: 100%;
  }

  .button-row {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &.single {
      justify-content: flex-end;
    }
  }
}
