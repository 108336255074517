@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&family=Oswald&family=Poppins&family=Roboto&family=Rubik&family=Work+Sans&display=swap');

.widget-business-wrapper {
  .header {
    box-shadow: 0px 2px 8px 0px #5194d826;
  }

  .business-menu {
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .business-tabs {
      @media screen and (max-width: 768px) {
        order: 2;
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs .ant-tabs-nav {
    padding: 1px;
  }
}
