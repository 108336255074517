.action-form-items {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.code-block-container {
  background-color: #2e2e2e;
  color: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
  overflow: auto;
  font-family: 'Courier New', Courier, monospace;
  border: 1px solid #444;
  margin: 16px 0;
  pre {
    margin: 0;
  }

  code {
    display: block;
  }
}
