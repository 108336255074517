.page-break-wrapper {
  .drag-icon {
    .ant-btn {
      border-radius: 0px 5px 5px 0px;
    }
  }
  .delete-icon {
    .ant-btn {
      border-radius: 5px 0px 0px 5px;
    }
  }
  .divider-step {
    flex: 1;
    .ant-divider-horizontal.ant-divider-with-text::after {
      border-block-start: 1px dashed var(--highlight-color);
    }
    .ant-divider-horizontal.ant-divider-with-text::before {
      border-block-start: 1px dashed var(--highlight-color);
    }
    .ant-btn {
      border-radius: 5px;
      width: 135px;
    }
    .custom-button {
      pointer-events: none;
      background-color: var(--highlight-color);
      border-color: var(--highlight-color);
      color: var(--button-text-color);
    }

    .custom-button:hover,
    .custom-button:focus {
      background-color: var(--button-hover-color);
      border-color: var(--button-hover-color);
      color: var(--button-text-color);
    }

    .custom-button.ant-btn[disabled] {
      color: var(--button-text-color);
      background-color: #1890ff;
      border-color: #1890ff;
      opacity: 1;
    }
  }
}
