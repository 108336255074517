@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&family=Oswald&family=Poppins&family=Roboto&family=Rubik&family=Work+Sans&display=swap');

.booking-form-wrapper {
  .header {
    box-shadow: 0px 2px 8px 0px #5194d826;
    position: relative;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs .ant-tabs-nav {
    padding: 1px;
  }
}
