.conversation-cke-editor-wrapper {
  align-items: center;
  position: relative;

  .send-btn {
    width: 42px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 40px;
    top: 50%;
    box-shadow: none;
  }
}
