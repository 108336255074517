.widget-menu-wrapper {
  padding: 24px 24px 12px 24px;
  height: 100%;
  min-height: calc(100vh - 125px);
  background-color: transparent;
  position: static;

  .widget-group-title {
    color: inherit;
    opacity: 0.8;
    margin: 30px 0 0 2px;
    font-size: 12px;
  }
}
