.support-modal {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
  }

  .support-modal-tabs-wrapper {
    max-height: 50px;
  }
  .support-modal-content {
    max-height: 450px;
    min-height: 450px;
    margin-bottom: 40px;
  }
}
