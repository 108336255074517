@import 'src/@aduvi/styles/base/colors.scss';

.quantity-stock-wrapper {
  .radio-button-RCD {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
    text-align: center;
  }

  .product-type-description {
    font-size: 12px;
    color: $gray-8;
  }

  .product-type-active {
    font-size: 12px;
    color: $blue-6;
  }

  .product-type-title {
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 0;
  }

  .product-type-title-active {
    @extend .product-type-title;
    color: $blue-6;
  }
}
