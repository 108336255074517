.task-rows {
  .task-status {
    .status-options-green {
      .ant-select-selector {
        background-color: #b7eb8f;
      }
    }

    .status-options-yellow {
      .ant-select-selector {
        background-color: #ffe58f;
      }
    }

    .status-options-gray {
      .ant-select-selector {
        background-color: #f0f0f0;
      }
    }
  }

  .task-priority {
    .priority-options-green {
      .ant-select-selector {
        background-color: #b7eb8f;
      }
    }

    .priority-options-red {
      .ant-select-selector {
        background-color: #ff7875;
      }
    }
  }
}
